<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center">
      <h3>إشغال القاعات</h3>
      <b-button variant="primary" @click="onAdd()">
        إشغال قاعة
      </b-button>
    </div>
    <hr />
    <b-row>
      <b-col cols="3">
        <form-select :reduce="(el) => el.value" label="" v-model="status" placeholder=" ابحث هنا عن حالة القاعة..." class="w-50"
          :options="optionsType" />
      </b-col>

      <b-col cols="3">
        <form-input label="" v-model="filter" placeholder="ابحث هنا..." class="w-50" />
      </b-col>
    </b-row>


    <data-table striped responsive class="mb-0" :fields="fields" ep="hall-occupancies" :filter="filter"
      :utils="{ status }" ref="estatesTable">
      <template #cell(is_payment)="row">
        <div>{{ !row.item.is_payment ? "نعم" : "لا" }}</div>
      </template>
      <template #cell(status)="row">
        <div>
          {{
            row.item.status == "S"
              ? "مخطط"
              : row.item.status == "E"
                ? "منفذ"
                : "ملغى"
          }}
        </div>
      </template>

      <template #cell(actions)="row">
        <div class="d-flex">
          <b-button variant="flat-info" title="عرض المزيد من المعلومات" size="sm"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'" class="" v-b-tooltip.hover.v-info.top @click="row.toggleDetails"
            v-b-toggle.collapse-1>
            المزيد
            <feather-icon :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />

            <!-- ChevronUpIcon -->
          </b-button>
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item @click="onUpdateClicked(row.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">تعديل</span>
            </b-dropdown-item>
            <b-dropdown-item @click="activModelAppointime = true ; appointime_id = row.item.id">
              <feather-icon icon="PlusIcon" />
              <span class="align-middle ml-50">المواعيد</span>
            </b-dropdown-item>


            <b-dropdown-item @click="onDelete(row.item)">
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">حذف</span>
            </b-dropdown-item>

          </b-dropdown>
        </div>
      </template>

      <template #row-details="row">
        <b-collapse id="collapse-1" class="mt-2">
          <b-row>
            <b-col cols="5">
              <div>
                <h6 class="text-primary">نوع النشاط</h6>
                <p>
                  {{ row.item.hallActivityType.title }}
                </p>
              </div>
              <hr />
              <div>
                <h6 class="text-primary">التجهيزات المطلوبة</h6>
                <p>
                  {{ row.item.assets }}
                </p>
              </div>
            </b-col>
            <b-col cols="3">
              <div>
                <h6 class="text-primary">العدد المتوقع</h6>
                <p>
                  {{ row.item.expected_member_count }}
                </p>
              </div>
              <hr />
              <div>
                <h6 class="text-primary">العدد الفعلي</h6>
                <p>
                  {{ row.item.actual_member_count }}
                </p>
              </div>
            </b-col>
            <b-col cols="3">
              <h6 class="text-primary">التفاصيل</h6>
              <p>
                {{ row.item.activity_description }}
              </p>
              <div v-if="row.item.cost">
                <hr />
                <h6 class="text-primary">الكلفة</h6>
                <p>
                  {{ row.item.cost.toLocaleString("en-US") }}
                </p>
              </div>

              <div v-if="row.item.cancel_reason">
                <hr />
                <h6 class="text-primary">سبب الإلغاء</h6>
                <p>
                  {{ row.item.cancel_reason }}
                </p>
              </div>
            </b-col>
          </b-row>
        </b-collapse>
      </template>
    </data-table>

    <form-modal ref="estateModalOccupancy" :formSchema="estatesFormSchema" title="إضافة " v-model="activModel"
      :loading="loading" @confirm="onModalConfirmed">
      <template #cost="item">
        <form-mask v-if="item.form.is_payment" label="الكلفة" options="number" rules="required" dir="ltr"
          placeholder="1,000" v-model="item.form.cost" />
      </template>
      <template #cancel_reason="item">
        <form-textarea v-if="item.form.status == 'C'" label="سبب الإلغاء" v-model="item.form.cancel_reason" />
      </template>
    </form-modal>

    <appointime v-model="activModelAppointime" :appointime_id="appointime_id">

    </appointime>
  </b-card>
</template>
<script>
import {
  BCard,
  BCol,
  BRow,
  BSkeleton,
  BDropdown,
  VBTooltip,
  VBToggle,
  BButton,
  BCollapse,
  BDropdownItem,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import DataTable from "@/components/data-table/index.vue";
import Ripple from "vue-ripple-directive";
import FormModal from "@/components/FormModal.vue";
import FormMask from "@/components/form-mask/index.vue";
import FormTextarea from "@/components/form-textarea";
import Appointime from "../../components/Appointime.vue";
import FormInput from "@/components/form-input/index.vue";
import FormSelect from "@/components/form-select/index.vue"
export default {
  components: {
    BCard,
    FormTextarea,
    BCol,
    BRow,
    BSkeleton,
    DataTable,
    VBTooltip,
    VBToggle,
    BDropdown,
    BDropdownItem,
    BButton,
    BCollapse,
    FormModal,
    FormMask,
    Appointime,
    FormInput,
    FormSelect
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  data: () => {
    return {
      filter: "",
      status: "",
      fields: [
        { key: "title", label: "اسم النشاط", sortable: true },
        { key: "hall.code", label: "القاعة" },
        { key: "agency.name", label: "الجهة" },
        { key: "status", label: "الحالة" },
        { key: "is_payment", label: "هل هي مجانية" },
        { key: "actions", label: "" },
      ],
      optionsType: [
        { label: "مخطط", value: "S" },
        { label: "منفذ", value: "E" },
        { label: "ملغى", value: "C" },
      ],
      activModel: false,
      activModelAppointime: false,
      appointime_id: null,
      estatesFormSchema: {
        title: {
          component: "FormInput",
          key: "title",
          attrs: {
            label: "اسم النشاط",
            rules: "required",
          },
        },

        activity_description: {
          component: "FormTextarea",
          key: "activity_description",
          attrs: {
            label: "التفاصيل",
            rules: "required",
          },
        },
        assets: {
          component: "FormTextarea",
          key: "assets",
          attrs: {
            id: "assets",
            label: "التجهيزات المطلوبة",
            rules: "required",
          },
        },
        is_payment: {
          component: "FormSelect",
          key: "is_payment",
          attrs: {
            id: "is_payment",
            label: "هل هي مجانية",
            rules: "required",
            reduce: (el) => el.value,
            options: [
              { label: "نعم", value: 0 },
              { label: "لا", value: 1 },
            ],
          },
        },
        cost: {
          key: "cost",
        },
        expected_member_count: {
          component: "FormMask",
          key: "expected_member_count",
          attrs: {
            label: "العدد المتوقع",
            options: "number",
            rules: "required",
            dir: "ltr",
            placeholder: "",
          },
        },
        actual_member_count: {
          component: "FormMask",
          key: "actual_member_count",
          attrs: {
            label: " العدد الفعلي ",
            options: "number",
            rules: "required",
            dir: "ltr",
            placeholder: "",
          },
        },
        status: {
          component: "FormSelect",
          key: "status",
          attrs: {
            id: "status_occ",
            label: "الحالة",
            rules: "required",
            reduce: (el) => el.value,
            options: [
              { label: "مخطط", value: "S" },
              { label: "منفذ", value: "E" },
              { label: "ملغى", value: "C" },
            ],
          },
        },
        hall_activity_type_id: {
          component: "FormSelect",
          key: "hall_activity_type_id",
          attrs: {
            reduce: (x) => x.id,
            getOptionLabel: (x) => x.title,
            id: "hall_activity_type_id_occ",
            label: "نوع النشاط",
            ep: "select/hall-activity-types",
            rules: "required",
          },
        },
        hall_id: {
          component: "FormSelect",
          key: "hall_id",
          attrs: {
            reduce: (x) => x.id,
            getOptionLabel: (x) => x.code,
            id: "hall_id",
            label: "القاعة",
            ep: "select/halls",
            rules: "required",
          },
        },
        agency_id: {
          component: "FormSelect",
          key: "agency_id",
          attrs: {
            reduce: (x) => x.id,
            getOptionLabel: (x) => x.name,
            id: "agency_id",
            label: "الجهة",
            ep: "select/agencies",
            rules: "required",
          },
        },
        cancel_reason: {
          key: "cancel_reason",
        },
      },
    };
  },
  methods: {
    ...mapActions("manageCenter/hallsOccupancy", [
      "updateHallsOccupancy",
      "createHallsOccupancy",
      "deleteHallsOccupancy",
      "createAppointments",
      "deleteAppointments",
      "updateAppointments",
    ]),
    onAdd() {
      this.$refs.estateModalOccupancy.init({});
      this.activModel = true;
    },
    onDelete(item) {
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف الإشغال", {
          title: "تأكيد حذف الإشغال",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.deleteHallsOccupancy({ id: item.id }).then(() => {
              this.$refs.estatesTable.refreshTable();
            });
        });
    },
    onUpdateClicked(form) {
      var toUpdate = {
        id: form.id,
        title: form.title,
        activity_description: form.activity_description,
        assets: form.assets,
        is_payment: form.is_payment,
        hall_activity_type_id: form.hallActivityType.id,
        cost: form.cost,
        expected_member_count: form.expected_member_count,
        actual_member_count: form.actual_member_count,
        status: form.status,
        cancel_reason: form.cancel_reason,
        hall_id: form.hall.id,
        agency_id: form.agency.id,
      };
      this.$refs.estateModalOccupancy.init(toUpdate);
      this.activModel = true;
    },
    onUpdateClickedAppointime(form) {
      var toUpdate = {
        id: form.id,
      };
      this.$refs.estateModalOccupancy.init(toUpdate);
      this.activModel = true;
    },

    onModalConfirmed(form) {
      let newForm = JSON.parse(JSON.stringify(form));
      for (let item of Object.keys(newForm)) {
        if (item == "cost") {
          newForm[item] = form[item].replace(/\D/g, "");
        }
      }

      if (form.id) {
        this.updateHallsOccupancy({ id: form.id, data: newForm }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
      } else
        this.createHallsOccupancy({ data: newForm }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
    },

    onModalConfirmedDate(form) {
      if (form.id) {
        this.updateHallsOccupancy({ id: form.id, data: {...form , 
          expected_member_count: form.expected_member_count.replace(/\D/g, ""),
          actual_member_count:form.actual_member_count.replace(/\D/g, "")
        } }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
      } else
        this.createHallsOccupancy({ data: {...form , 
          expected_member_count: form.expected_member_count.replace(/\D/g, ""),
          actual_member_count:form.actual_member_count.replace(/\D/g, "")
        } }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
    },
  },
  watch:{
    status(){
      this.$refs.estatesTable.refreshTable();
    }
  },
  computed: {
    ...mapGetters("manageCenter/hallsOccupancy", ["loading"]),
  },
};
</script>